<template>
  <div class="payment-method-form">
    <layout-loading v-if="!formLoaded" />
    <validation-observer v-else ref="observer" v-slot="{ passes }">
      <b-form class="form" @submit.prevent="passes(submit)">
        <div
          :class="{
            'payment-method-form__credit-card': true,
            form__section: true,
          }"
        >
          <h2>{{ paymentMethod.id ? $t("credit_card") : $t("new_credit_card") | capitalize }}</h2>
          <div>
            {{ $t("fees_notice") }}
            <ul>
              <li :class="{ 'font-weight-bold': cardType === 'amex' }">
                {{ $t("fee_types.amex") | capitalize }}&nbsp;:&nbsp;{{ fees.amex.ratio | percent }}
              </li>
              <li :class="{ 'font-weight-bold': cardType === 'foreign' }">
                {{ $t("fee_types.non_canadian") | capitalize }}&nbsp;:&nbsp;{{
                  fees.foreign.ratio | percent
                }}
                +
                {{ fees.foreign.constant | currency }}
              </li>
              <li :class="{ 'font-weight-bold': cardType === 'canadian' }">
                {{ $t("fee_types.canadian") | capitalize }}&nbsp;:&nbsp;{{
                  fees.default.ratio | percent
                }}
                +
                {{ fees.default.constant | currency }}
              </li>
            </ul>
          </div>
          <div v-if="!paymentMethod.id">
            <div id="stripe-card" class="form-control"></div>
            <b-form-invalid-feedback v-if="error">{{ error.message }}</b-form-invalid-feedback>
          </div>
        </div>

        <div class="form__buttons">
          <icon-button
            role="save"
            type="submit"
            :disabled="error || loading"
            :loading="saving"
            v-if="!paymentMethod.id"
          >
            {{ $t("forms.save") | capitalize }}
          </icon-button>
          <icon-button
            role="delete"
            v-if="!!paymentMethod.id"
            @click="destroy"
            :loading="deleting"
            :disabled="loading"
          >
            {{ $t("forms.delete") | capitalize }}
          </icon-button>
          <template v-if="showCancel">
            &nbsp;
            <icon-button role="cancel" @click="$emit('cancel')">Annuler</icon-button>
          </template>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import { loadStripe } from "@stripe/stripe-js";

import FormsBuilder from "@/components/Forms/Builder.vue";
import { cardFeeSpecs } from "@/helpers/transactionFees";

import locales from "@/locales";
import LayoutLoading from "@/components/Layout/Loading.vue";

export default {
  name: "PaymentMethodForm",
  async mounted() {
    if (this.shown) {
      await this.initializeStripeField();
    }
  },
  components: {
    IconButton,
    LayoutLoading,
    FormsBuilder,
  },
  props: {
    shown: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      card: null,
      stripe: null,
      error: null,
      saving: false,
      deleting: false,
      fees: cardFeeSpecs,
    };
  },
  computed: {
    formLoaded() {
      return this.form && this.paymentMethod;
    },
    user() {
      return this.$store.state.user;
    },
    paymentMethodState() {
      return this.$store.state.paymentMethods;
    },
    form() {
      return this.paymentMethodState.form;
    },
    paymentMethod() {
      return this.paymentMethodState.item;
    },
    loading() {
      return this.paymentMethodState.loading || this.saving || this.deleting;
    },
    country() {
      return this.paymentMethod?.country;
    },
    brand() {
      return this.paymentMethod?.credit_card_type;
    },
    cardType() {
      if (!this.country || !this.brand) {
        return undefined;
      }
      if (this.brand === "American Express") {
        return "amex";
      }
      if (this.country !== "CA") {
        return "foreign";
      }
      return "canadian";
    },
  },
  methods: {
    async initializeStripeField() {
      const element = document.getElementById("stripe-card");

      if (element) {
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
        const card = stripe.elements({ locale: "fr" }).create("card", {
          hidePostalCode: true,
        });
        card.mount(element);

        this.card = card;
        this.stripe = stripe;
      }
    },
    async destroy() {
      this.deleting = true;
      try {
        await this.$store.dispatch("paymentMethods/destroy", { id: this.paymentMethod.id });
        this.$emit("destroyed");
      } finally {
        this.deleting = false;
      }
    },

    async submit() {
      this.saving = true;
      try {
        const { token, error } = await this.stripe.createToken(this.card, {
          name: `${this.user.full_name}`,
          address_country: "CA",
          address_line_1: `${this.user.address}`,
          address_zip: `${this.user.postal_code}`,
        });

        const { id, card } = token;

        this.paymentMethod.external_id = id;
        this.paymentMethod.credit_card_type = card.brand;
        this.paymentMethod.name = `${card.brand} se terminant par ${card.last4}`;
        await this.$store.dispatch(`paymentMethods/createItem`, { params: this.params });
        await this.$store.dispatch("loadUser");
        this.$emit("created");
      } finally {
        this.saving = false;
      }
    },
  },
  watch: {
    formLoaded(loaded) {
      if (loaded) {
        this.$nextTick(this.initializeStripeField);
      }
    },
    shown(shown) {
      if (shown) {
        this.initializeStripeField();
      }
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.paymentMethods,
      },
      fr: {
        ...locales.fr.paymentMethods,
      },
    },
  },
};
</script>
